@import "~@ui/styles/tools";
@import "src/styles/variables";

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1280px) {
        @content;
    }
}
.container {
    position: relative;
    overflow-x: hidden;

    :global(.react-slideshow-container) {

        button {
            &:disabled {
                display: none;
            }
        }

    }
}

.item-wrapper {
    margin: 0 20px;
}

.slider {
    :global(.slick-slide) {
        & > div {
            height: 100%;
        }
    }

    :global(.slick-arrow) {
        height: 50px;
        width: 50px;
        border:none;
        border-radius: 50%;
        background-color: #1e2021;
        cursor: pointer;
    
        display: flex !important;
        justify-content: center;
        align-items: center;

        opacity: 1;

        &:hover {
            background-color: #363a3c;
        }
    }

    :global(.slick-prev) {
        transform: translateY(-50%) translateX(calc(-100% - 8px)) rotate(180deg)  ;
        right: 0;
        left: unset;
    }

    :global(.slick-next) {
        transform: translateY(-50%);
        right: 0;
        left: unset;
    }

    :global(.slick-disabled) {
        display: none !important;
        visibility: hidden;
    }
}

@include small-breakpoint {
    .item-wrapper {
        margin: 0 10px;
    }
}

@include media-tablet {
    .container {
        & > .slim {
            & > div > ul {
                margin-left: 20%;
            }
        }
    }

    .item-wrapper {
        margin: 0 5px;
        height: 100%;
    }
}

@media only screen and (max-width: 600px){
    .container {
        & > .slim {
            width: max(70%, 282px);
        }
    }
}
